import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { applicationConfig } from '../environments/application';
import { environment } from '../environments/environment';
import { Language } from 'projects/_shared/models/enums';
import { LanguageService } from 'projects/_shared/shared/helper-service/language.service';
import { UserService } from 'projects/_shared/shared/helper-service/user.service';
import { Subscription, first } from 'rxjs';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { EpaasHelperService } from 'projects/_shared/shared/helper-service/epaas-helper.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { route, RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { AccessibilityHelperService, LoginService, TimeoutService, UrlBuilderService } from 'customerdigital-service-lib';
import { IconType, FooterMenuModel, ImageModel, TopNavMenuItem } from 'customerdigital-ui-components-lib';
import { HeaderMenuItemType, FooterMenuItemTypeForMini } from './shared/enum';
import { Constants } from 'projects/_shared/shared/constants';
import { FilterService } from 'projects/_shared/shared/helper-service/filter.service';
import { Store } from '@ngrx/store';
import { SessionTimeoutModel } from 'projects/_shared/shared/models/sessionTimeout.model';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  public brand = applicationConfig.BRAND;
  public country = applicationConfig.COUNTRY;
  public environment = environment.ENV;
  public dataloaded: boolean = false;
  private dataloadedSubScription: Subscription = new Subscription();
  sessionTimeoutConfig!:SessionTimeoutModel;
  idleTime!: number;
  timeoutTime!: number;
  hasLoggedInStateSubscription: Subscription = new Subscription();
  routerEventSubscribe: any;
  previousUrl: string = "";
  currentUrl: string = ""
  skipToMainUrl: string = "";
  footerMenuItems = new Array();
  tempFooterMenuItems = new Array();
  selectedIndex = 0;
  privacyUrl: any;
  domainUrl: any;
  copyRightText: string = "";
  copyRightTextMobile: string = "";
  public footerMenuList: any[] = [];
  public epassInitializedSubscribe: any;
  isEpaasError: boolean = false;
  public mainHeader: any;

  constructor(private languageService: LanguageService,
    private userService: UserService,
    private store: Store<IAppState>,
    private router: Router,
    private loginService: LoginService,
    private accessibilityService: AccessibilityHelperService,
    private urlBuilderService: UrlBuilderService,
    private timeoutService: TimeoutService,
    private sessionTimeoutService: SessionTimeoutService,
    private translateService: TranslateService,
    private routerService: RouterService,
    private routes: ActivatedRoute,
    private epaasHelperService: EpaasHelperService,
    private filterService: FilterService) {

    this.routes.queryParams.subscribe(params => {
      let language = params['language'];
      if (language != undefined) {
        switch (language) {
          case Language.English:
            this.toogleToEnglish();
            break;
          case Language.French:
            this.toogleToFrench();
            break;
        }
      }
    });
    this.dataloadedSubScription = this.store.select(state => state.DataLoaded)
      .subscribe(state => {
        this.dataloaded = state!;
      });
  }

  ngOnInit(): void {
    this.store.select(x=> x.EnvironmentConfig?.IDLE_SESSION).subscribe(s=> this.idleTime = s ?? 0)
    this.store.select(x=> x.EnvironmentConfig?.TIME_OUT_SESSION).subscribe(s=> this.timeoutTime = s ?? 0)
    this.sessionTimeoutService.handleIdleSessionTimeOut();
    this.translateService.onDefaultLangChange.pipe(first()).subscribe((event: LangChangeEvent) => {
      this.epaasHelperService.initializeEpaas();
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initializeHeader();
      this.initializeFooter();
      this.filterFooterMenu();
    });
    // Code To start ngIdle service after sso authentication completed
    this.routerEventSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.skipToMainUrl = `${event.url}${Constants.SkipToMainFragment}`;
        if (this.userService.isAuthenticated()) {
          this.timeoutService.initIdle(this.idleTime * 60, this.timeoutTime * 60);
          this.routerEventSubscribe.unsubscribe();
        }
      }
    });
    this.initializeHeader();
    this.initializeFooter();
  }

  private filterFooterMenu(): void {
    this.store.select(state => state.CannotAuthenticate)
      .subscribe(error => {
        this.isEpaasError = error! && error.booleanValue;
      });
    this.epassInitializedSubscribe = this.store.select(state => state.IsEpassInitialized).subscribe(
      (value) => {
        if (value && value.booleanValue) {
          if (this.isEpaasError) {
            this.footerMenuList = this.filterService.filterFooterMenuForMini(this.footerMenuItems, FooterMenuItemTypeForMini.CookiePolicy);
          } else {
            this.footerMenuItems = this.tempFooterMenuItems;
            this.footerMenuList = this.filterService.filterFooterMenuForMini(this.footerMenuItems);
          }
        }
        else {
          this.footerMenuList = this.filterService.filterFooterMenuForMini(this.footerMenuItems, FooterMenuItemTypeForMini.CookiePolicy);
        }
      }
    )

    this.footerMenuItems = this.footerMenuList;
  }

  ngOnDestroy() {
    this.dataloadedSubScription.unsubscribe();
    if (this.hasLoggedInStateSubscription) {
      this.hasLoggedInStateSubscription.unsubscribe();
    }
  }

  toogleToEnglish() {
    this.selectedIndex = 0;
    this.languageService.setCurrentLanguage(Language.English);
  }

  toogleToFrench() {
    this.selectedIndex = 1;
    this.languageService.setCurrentLanguage(Language.French);
  }

  public get isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  private initializeHeader() {
    let myAccountHeaderMenuItem: TopNavMenuItem,
      editHeaderMenuItem: TopNavMenuItem,
      logoutHeaderMenuItem: TopNavMenuItem;
    let topNavigation: string = 'topNavigation';
    this.translateService.get(topNavigation).subscribe(result => {
      this.mainHeader = {
        logo: new ImageModel("", Constants.miniCaLogoPath, ""),
        headerTitle: result.header,
        menuTitle: result.dropdown.header ,
        ariaLabelText: result.logoAriaLabel,
        skipToMainLinkText: result.skip,
        topNavMenuItems: []
      };
      myAccountHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionMyaccount, IconType.MyAccount, false, HeaderMenuItemType.MyAccount);
      editHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionEdit, IconType.Edit, false, HeaderMenuItemType.EditUsername);
      logoutHeaderMenuItem = new TopNavMenuItem(result.dropdown.optionLogout, IconType.Logout, false, HeaderMenuItemType.Logout);
      this.mainHeader.topNavMenuItems.push(logoutHeaderMenuItem);
      this.addHeaderMenuItems([myAccountHeaderMenuItem, editHeaderMenuItem]);
    });

    this.hasLoggedInStateSubscription = this.loginService.hasLoggedInState.subscribe({
      next: (response: any) => {
        if (!this.mainHeader.topNavMenuItems.find((menu: { text: string; }) => menu.text == myAccountHeaderMenuItem.text))
          this.addHeaderMenuItems([myAccountHeaderMenuItem, editHeaderMenuItem]);
      }
    });

    this.accessibilityService.setRoleOfOverlay();
  }

  private addHeaderMenuItems(topNavMenuItems: TopNavMenuItem[]) {
    if (this.userService.isTermsAccepted()) {
      this.mainHeader.topNavMenuItems.unshift(...topNavMenuItems);
    }
  }

  public onMenuItemSelect(eventName: HeaderMenuItemType) {
    switch (eventName) {
      case HeaderMenuItemType.MyAccount:
        this.navigateToMyAccount();
        break;
      case HeaderMenuItemType.EditUsername:
        this.editUsername();
        break;
      case HeaderMenuItemType.Logout:
        this.logout();
        break;
    }
  }

  public logoLinkClick() {
    if (this.userService.isTermsAccepted()) {
      this.navigateToMyAccount();
    }
  }

  private navigateToMyAccount() {
    let loginUri: string|undefined;
    this.store.select(state => state.EnvironmentConfig!.CLIENT_CONFIG[0]['loginUri']).subscribe(x => loginUri = x);
    window.location.href = loginUri + Constants.UrlParametersPrefix + this.languageService.getLanguageParameter();
  }

  private editUsername() {
    let externalUrl = this.urlBuilderService.getEditUsernameRedirectUrl();
    this.routerService.navigateToExternalUrl(externalUrl, true);

  }

  private logout() {
    this.routerService.navigateToSSOLogout();
  }

  private initializeFooter() {
    this.translateService.get("footer.copyright-text-desktop", { currentYear: new Date().getFullYear() }).subscribe(res => {
      this.copyRightText = res;
    });
    this.translateService.get("footer.copyright-text-mobile", { currentYear: new Date().getFullYear() }).subscribe(res => {
      this.copyRightTextMobile = res;
    });

    let languageEnglishFooterMenuItem: FooterMenuModel,
      languageFrenchFooterMenuItem: FooterMenuModel,
      privacyFooterMenuItem: FooterMenuModel,
      termsFooterMenuItem: FooterMenuModel,
      faqFooterMenuItem: FooterMenuModel,
      cookiePolicyFooterMenuItem: FooterMenuModel,
      contactUsFooterMenuItem: FooterMenuModel,
      miniCAFooterMenuItem: FooterMenuModel;

    let languageEnglishKey: string = 'footer.language-english';
    let languageFrenchKey: string = 'footer.language-french';
    let privacyLegalKey: string = 'footer.privacy-legal';
    let termsConditionsKey: string = 'footer.terms-conditions';
    let faqKey: string = 'footer.faq';
    let cookiePolicyKey: string = 'footer.cookie-policy';
    let contactusKey: string = 'footer.contact-us';
    let miniCAKey: string = 'footer.mini-ca';
    let privacyPolicyUrlKey: string = 'footer.privacy-policy-url';
    let domainUrlKey: string = 'footer.domain-url';

    this.translateService.get([languageEnglishKey, languageFrenchKey, privacyLegalKey, termsConditionsKey, faqKey, cookiePolicyKey, contactusKey, miniCAKey, privacyPolicyUrlKey, domainUrlKey]).subscribe(result => {
      languageEnglishFooterMenuItem = new FooterMenuModel(result[languageEnglishKey], FooterMenuItemTypeForMini.LanguageEnglish, result[languageEnglishKey]);
      languageFrenchFooterMenuItem = new FooterMenuModel(result[languageFrenchKey], FooterMenuItemTypeForMini.LanguageFrench, result[languageFrenchKey]);
      privacyFooterMenuItem = new FooterMenuModel(result[privacyLegalKey], FooterMenuItemTypeForMini.PrivacyAndLegal, result[privacyLegalKey]);
      termsFooterMenuItem = new FooterMenuModel(result[termsConditionsKey], FooterMenuItemTypeForMini.TermsAndConditions, result[termsConditionsKey]);
      faqFooterMenuItem = new FooterMenuModel(result[faqKey], FooterMenuItemTypeForMini.FAQ, result[faqKey]);
      cookiePolicyFooterMenuItem = new FooterMenuModel(result[cookiePolicyKey], FooterMenuItemTypeForMini.CookiePolicy, result[cookiePolicyKey]);
      contactUsFooterMenuItem = new FooterMenuModel(result[contactusKey], FooterMenuItemTypeForMini.ContactUs, result[contactusKey]);
      miniCAFooterMenuItem = new FooterMenuModel(result[miniCAKey], FooterMenuItemTypeForMini.MINICA, result[miniCAKey]);
      this.footerMenuItems = [];
      this.tempFooterMenuItems = [];
      this.addFooterMenuItems([languageEnglishFooterMenuItem, languageFrenchFooterMenuItem, privacyFooterMenuItem, termsFooterMenuItem, faqFooterMenuItem, cookiePolicyFooterMenuItem, contactUsFooterMenuItem, miniCAFooterMenuItem]);
      this.privacyUrl = result[privacyPolicyUrlKey];
      this.domainUrl = result[domainUrlKey];
    });
  }
  private addFooterMenuItems(footerMenuItems: FooterMenuModel[]) {
    this.footerMenuItems.unshift(...footerMenuItems);
    this.tempFooterMenuItems.unshift(...footerMenuItems);
  }
  public onFooterMenuItemSelect(event: any) {
    const isNewTab = true;
    if (event) {
      switch (event.eventName) {
        case FooterMenuItemTypeForMini.LanguageEnglish:
          this.toogleToEnglish();
          break;
        case FooterMenuItemTypeForMini.LanguageFrench:
          this.toogleToFrench();
          break;
        case FooterMenuItemTypeForMini.PrivacyAndLegal:
          this.routerService.navigateToExternalUrl(this.privacyUrl, isNewTab);
          break;
        case FooterMenuItemTypeForMini.TermsAndConditions:
          this.routerService.navigateToNewTabWithParam(route.termsview, this.languageService.getLanguageParameter());
          break;
        case FooterMenuItemTypeForMini.FAQ:
          this.routerService.navigateToFaqWithLang(this.languageService.getCurrentLanguage());
          break;
        case FooterMenuItemTypeForMini.CookiePolicy:
          this.routerService.navigateToCookiePolicy();
          break;
        case FooterMenuItemTypeForMini.ContactUs:
          let contactUs: string = "";
          this.translateService.get('footer.contact-us-navigation').subscribe((res: any) => {
            contactUs = res;
          });
          this.routerService.navigateToFaqWithLang(this.languageService.getCurrentLanguage(), contactUs);
          break;
        case FooterMenuItemTypeForMini.MINICA:
          this.routerService.navigateToExternalUrl(this.domainUrl, isNewTab);
          break;
      }
    }
  }
}