import { Component,OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import {ButtonState} from 'customerdigital-ui-components-lib';

@Component({
  selector: 'app-terms',
  templateUrl: "./terms.component.html",
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent  {
  /** Used to display Header title in Terms Page*/
  @Input() headTitle!: string;
  /** Used to display checkbox label in Terms Page*/
  @Input() checkboxLabel!: string ;
  /** Used to display primary Button text in Terms Page*/
  @Input() primaryBtnText!: string;
  /** Used to display secondary Button text in Terms Page*/
  @Input() secondaryBtnText!: string;
  /** Used to include content within Terms Page*/
  @Input() termsContentHtml!: SafeHtml;
  /** Used to display checkox link Terms Page*/
  @Input() checkboxLink!: string;
  /** Used to accept terms in Terms Page*/
  @Input() termsAccepted!: boolean ;
  /** Used to display error when service is failed in Terms Page*/
  @Input() termsLoadFailed!: boolean; 
  /** Used to display error message when service is failed in Terms Page*/
  @Input() errorMessage!: string;
  /** Used to display error when checkbox is not checked Terms Page*/
  @Input() showError!:  boolean;
  /** Used to display error message when checkbox is not checked in Terms Page*/
  @Input() checkboxErrorMessage!: string;

  @Input() primaryBtnAriaLabel!: string;
  @Input() secondaryBtnAriaLabel!: string;

  @Input() primaryBtnAnalyticsEvent!: string;
  @Input() secondaryBtnAnalyticsEvent!: string;
  @Input() brandType!: string;
  /** Event emitted when user clicks on the anchor.*/
  @Output() onTermsAccepted: EventEmitter<any> = new EventEmitter<any>();
  /** Event emitted when user clicks on the anchor. */
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  /** Event emitted when user clicks on the anchor. */
  @Output() onContinue: EventEmitter<any> = new EventEmitter<any>();
  checked: boolean = false;
  buttonState=ButtonState.Disable;

   public navigateToTermsView(): void{
    this.checked = !this.checked;
    this.showError = !this.checked
    this.termsAccepted = this.checked;
    this.onTermsAccepted.emit();
   }
   public cancelTerms(): void{
    this.onCancel.emit();
   }
   public continueTerms(): void{
    this.onContinue.emit();
   }
  onChecked(event: any){
    this.termsAccepted = event.checked;
    this.showError = !event.checked;
    this.buttonState = this.termsAccepted ? ButtonState.Active : ButtonState.Disable 
  }
}
