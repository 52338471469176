import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
declare const epaas : any;

if (environment.PRODUCTION) {
  enableProdMode();
}

((epaas?.api) || window).addEventListener('consentcontroller.api.initialized', bootStrapApp);

function bootStrapApp(){
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
}
