import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../../_shared/shared/helper-service/user.service';
import { AuthService } from '../../../../../_shared/web-service/auth.service';
import { route, RouterService } from '../../../../../_shared/shared/helper-service/router.service';
import { LanguageService } from '../../../../../_shared/shared/helper-service/language.service';
import { LoggerHelperService } from 'projects/_shared/shared/helper-service/logger-helper.service';
import { Constants } from 'projects/_shared/shared/constants';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { StringModel } from 'projects/_shared/models/string.model';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-terms-accept',
  templateUrl: "./terms-accept.component.html",
  styleUrls: ['./terms-accept.component.scss']
})

export class TermsAcceptComponent implements OnDestroy {

  termsContentHtml !: SafeHtml;
  termsLoadFailed = false;
  private langChangeSubscription: any;

  dataLoaded! : boolean;
  dataLoadedSubscription: Subscription;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private routerService: RouterService,
    private spinnerService: SpinnerHelperService,
    private sanitizer: DomSanitizer,
    private loggerHelperService: LoggerHelperService,
    private transService: TranslateService,
    private store: Store<IAppState>,
    private languageService: LanguageService
  ) {
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded)
      .subscribe(loaded => this.dataLoaded = loaded!);
    
    this.langChangeSubscription = this.store.select(state=> state.Language).subscribe(() => {
        this.getTermsHtml();
      }
    );
  }

  private getTermsHtml(){    
    this.spinnerService.setDataLoaded(false);
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
    this.dataLoadedSubscription.unsubscribe();
  }

  displayTermsCondition(success: boolean, terms: string) {
    if (success) {
      this.termsContentHtml = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
    this.spinnerService.setDataLoaded(true);
  }

  cancelTerms() {
    this.routerService.navigateToSSOLogout();
  }

  acceptTerms() {
    this.spinnerService.setDataLoaded(false);
    let gcid = this.userService.getGcid();
    this.authService.acceptTermsAndConditions(gcid)
      .subscribe(response => { this.postAcceptTerms(response); });
  }

  private postAcceptTerms(response: boolean) {
    if (response) {
      this.userService.setTermsAccepted(response);
      this.routerService.navigateToBaseUrl();
    } else {
      this.loggerHelperService.logCritical('Error occurred in TermsAcceptComponent, Gcid:' +
        this.userService.getGcid() + ', authService.acceptTermsAndConditions().');
      this.cancelTerms();
    }
    this.spinnerService.setDataLoaded(true);    
  }
  termsView(){
    window.open(route.termsview + Constants.UrlParametersPrefix + this.languageService.getLanguageParameter());
  }

}
