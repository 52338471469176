<div class="container">
  <app-epaas-error></app-epaas-error>
<fs-topnav 
    *ngIf="mainHeader"
    [logo]="mainHeader.logo"
    [ariaLabelText]="mainHeader.ariaLabelText"
    [isLoggedIn]="isAuthenticated"
    [menuTitle]="mainHeader.menuTitle"
    [headerTitle]="mainHeader.headerTitle"
    [skipToMainLinkText]="mainHeader.skipToMainLinkText"
    [skipToMainLinkUrl]="skipToMainUrl"
    [topNavMenuItems]="mainHeader.topNavMenuItems"
    (onMenuItemSelect)="onMenuItemSelect($event)" (logoLinkClick)="logoLinkClick()">
    </fs-topnav>
  <div class="content-container" role="main">
    <router-outlet></router-outlet>
    <app-spinner *ngIf="!dataloaded"></app-spinner>
  </div>

  <div class="mini-footer" *ngIf="dataloaded">
    <fs-auth-hub-footer [copyRightText]="copyRightText" [copyRightTextMobile]="copyRightTextMobile"
      [footerMenuItems]="footerMenuItems" (onClick)="onFooterMenuItemSelect($event)" [selectedIndex]="selectedIndex">
    </fs-auth-hub-footer>
  </div>
</div>