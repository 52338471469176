import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from 'projects/_shared/shared/helper-service/language.service';
import { IAppState } from "../../shared/store/app.store";
import { Constants } from '../../shared/constants';
import * as _ from "lodash";
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { Store } from '@ngrx/store';
import { AppTileModel } from 'projects/_shared/models/app-tile.model';

export const HomeResolve : ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<IAppState>);
  const languageService = inject(LanguageService);
  const routerService = inject(RouterService);

  let clientConfig : AppTileModel[];
    store.select(state => state.EnvironmentConfig!.CLIENT_CONFIG).subscribe(x=> clientConfig =x);
    let appTile = _.find(clientConfig!, function (appTile) { return appTile.autoredirect; })
    if (appTile != undefined) {
       routerService.navigateToExternalUrl(`${appTile.loginUri}${Constants.UrlParametersPrefix}${languageService.getLanguageParameter()}`);
    }
}